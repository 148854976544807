import React from "react"

export default function Page(props) {
  return (
    <div className="container mx-auto">
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="block text-base text-center text-brand font-semibold tracking-wide uppercase">
                What we're about
              </span>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                “We believe digital innovation should be open and sustainable,
                so that everyone has the opportunity to build great things!”
              </span>
            </h1>
            <p className="mt-32">
              <strong>
                Businesses need to digitally transform in order to survive in an
                increasingly competitive ‘Data Driven’ market.
              </strong>
            </p>
            <figure>
              <img
                className="w-full rounded-lg"
                src="/images/furnace-fact.png"
                alt=""
                // width="1310"
                // height="873"
              />
              {/* <figcaption>
                Sagittis scelerisque nulla cursus in enim consectetur quam.
              </figcaption> */}
            </figure>
            <blockquote>
              <p>
                <strong>
                  The fact is, we are experiencing in the 21st Century, a mass
                  extinction event in the corporate world.
                </strong>{" "}
                - Tom Seibel; Digital Transformaton: Survive and Thrive in an
                Era of Mass Extinction
              </p>
            </blockquote>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              Demand for the datafication of businesses, is driving talent
              toward roles and technologies that assist businesses transform
              into and maintain ‘Data Driven’ models.
              <br />
              <br /> The current complexity of Cloud infrastructure is far too
              high for most people and organisations to grasp and accumulate the
              skills to participate in this rapidly expanding demand for
              datafication. <br />
              <br />
              <strong>Furnace</strong> offers the opportunity to radically
              reduce the cost and complexity of Cloud and lower the barriers to
              participation in our new ‘Data Driven’ world.
            </p>
          </div>
          <div className="mt-6 prose prose-indigo prose-lg max-w-prose text-gray-500 mx-auto leading-8">
            <h2>Why Team Furnace?</h2>
            <p>
              Back in early 2016, when Danny (Co-founder) started working on
              financial trading infrastructure with Serverless and John
              (Co-Founder) was building a Cyber Services business, only a tiny
              percentage of the developer community had heard of Serverless and
              even fewer understood what it was or its potential. <br />
              <br />A year later we started building ‘Big Data’ applications to
              collect, analyse and store Cyber Security data. Over time, we
              understood the cost of running and managing the infrastructure was
              commercially unviable. So we decided to rebuild with Serverless.
              <br />
              <br />
              While we knew that going Serverless could offer us fantastic
              financial and man-hour savings, we also knew how hard it was to
              build in Serverless without an Enterprise class dev-team that we
              couldn’t hope to hire or retain. <br />
              <br />
              So, to help us solve that problem we created our own developer
              framework, to allow us to more quickly and easily build our own
              Serverless data pipelines and applications. <br />
              <br />
              As the framework matured and our understanding of Serverless grew,
              we realised our framework could be applied across many other ‘Data
              Driven’ industries, to help dev-teams and organisations rapidly
              develop cost-efficient applications.
              <br />
              <br /> Since then, the availability of Serverless products and
              services has spread across public Cloud. AWS, Azure, GCP, Alibaba,
              IBM, Oracle and more, offer a growing number of Serverless
              products and services. The word ‘Serverless’ is becoming more of a
              household name within the developer community, even if it is not
              yet widely understood. <br />
              <br />
              Current economic conditions are forcing Cloud users to educate
              themselves and find ways of creating the kind of efficiencies that
              Serverless offers. Even though the need is growing, developers and
              businesses continue to be constrained by;
            </p>
            <ul>
              <li>
                <strong>1.</strong> A lack of developer tools that provide the
                accessibility to build with Serverless and adopt FinDev
                practices
              </li>
              <li>
                <strong>2.</strong> Sufficient supply of talent to meet the
                demand of Enterprise and SME’s wanting to build and deploy
                Serverless operations
              </li>
            </ul>
            <p>
              <strong>Furnace</strong> gives developers access to all of the
              Serverless resources available across AWS, Azure and GCP, in a
              simplified manner. Developer best practices, security protocols
              and compliance guard-rails are built in. Dev-teams can accelerate
              ‘time to market’ of new products and services, without the
              encumbrance of a costly dev-management layer. <br />
              <br />
              We also provide the visibility and control of application ‘cost
              per transaction’, which is required to support the emerging
              architectural practice of <strong>FinDev.</strong>
              <br />
              <br /> If you would like to know more about our journey, please
              read this blog written by Danny, Co-founder and CTO.{" "}
              <a
                href="https://medium.com/projectfurnace/introducing-furnace-e4d7b1b51932"
                className="underline"
              >
                Our Journey
              </a>
            </p>
            <h2>What is Serverless?</h2>
            <p>
              Serverless is an abstraction level over a company’s IT
              infrastructure, which offers significant advantages to business;
              <br />
              <br />
              <ul className="list-disc">
                <li>
                  It offers <strong>‘on demand’</strong> compute and storage
                  where there are no servers to manage, applications
                  automatically scale ‘up and down’ on demand and there is no
                  charge if its not being used{" "}
                </li>
                <li>
                  It allows business to think less about infrastructure
                  capacity, scaling, and server management and more on
                  software’s business value, revenue, cost, and the
                  relationships between them{" "}
                </li>
                <li>
                  A <strong>‘pay-per-use’</strong> or{" "}
                  <strong>‘consumption based’</strong> business model that can
                  bill consumers precisely by their usage on a transaction by
                  transaction basis.
                </li>
              </ul>
            </p>
            <p>
              To achieve the transparency required for FinDev and billing per
              transaction, a view into every component within each application
              is needed, from a platform such as Furnace.
            </p>
            <h2>What is FinDev?</h2>
            <p>
              FinDev{" "}
              <a
                href="https://www.youtube.com/watch?v=F0H7WuK56Nc"
                className="underline"
              >
                (As described by Yan Cui in this video)
              </a>{" "}
              is an emerging architectural practice, first defined by one of the
              leading thinkers in Serverless, Simon Wardley. It is the premise
              of ‘worth based development’, where the software practitioner can
              create a ‘variable cost’ business model and redirect investment to
              maximise returns.
              <br />
              <br /> The ROI (return on investment) in the codebase can be
              assessed at a very granular level and clear choices can made, such
              as, fund, optimise or discard. <br />
              <br /> Simply, a project manager or software developer can ask
              themselves, “If I build this application, in this way, with these
              running costs, will it give me, or the organisation I’m building
              it for, the desired return?”.
            </p>
            <p>
              This convergence of Finance and Software Operations offers many
              advantages;
            </p>
            <ul className="list-disc">
              <li>
                <strong>Capex to Opex</strong> – Serverless converts software
                operations from Capex (fixed costs) to Opex (variable costs).
                This means enterprises can focus on marginal costs and value,
                making better decisions as a consequence.
              </li>
              <li>
                <strong>Profitability and Value</strong> – Insight into a
                transaction’s revenue and cost relationship, helps businesses
                understand how profitable and valuable that feature is to
                customers.
              </li>
              <li>
                <strong>Identify Success</strong> – Combining the understood
                value and how often a feature is used relative to operating
                costs and revenue, a business can decide which transaction to
                invest in or eliminate.
              </li>
              <li>
                <strong>Forecast Revenue and Cost</strong> - With cost, revenue,
                and profitability numbers for each feature and transaction, the
                business can forecast their future values and growth rate.
              </li>
              <li>
                <strong>Focus on Growth</strong> – With growth rates
                anticipated, the business can focus on those transactions that
                will see increase in value and usage.
              </li>
              <li>
                <strong>Minimise Business Risk</strong> – Moving to Serverless
                decreases DOL (Degree of Operating Leverage). Profit generation
                has a lower dependency upon fixed costs as features and services
                move over to variable cost infrastructure.
              </li>
              <li>
                <strong>Lower Break-Even</strong> – As fixed costs are switched
                for variable costs, the break-even point of the business becomes
                lower.
              </li>
              <li>
                <strong>Optimise</strong> - Combining customer churn rate with
                how often a feature is used and its profitability, it is
                possible to understand which features are failing to serve
                customers and why.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
